import { loadTheme } from "./theme.js";
import { buildHeader } from "./header.js";
import { buildCards } from "./cards.js";

addEventListener("DOMContentLoaded", (event) => {
    document.getElementById("copyright-year").innerText = new Date().getFullYear();

    let reanimate = document.getElementById("reanimate");
    // check if the element exists
    if (reanimate) {
        reanimate.addEventListener("click", buildHeader);
        reanimate.addEventListener("touchstart", (e) => {
            buildHeader();
            e.preventDefault();
        });
    }

    loadTheme();

    // Wait until the fonts are all loaded
    document.fonts.ready.then(() => {
        // Use the font to render the charts
        debugger
        buildHeader();
        buildCards();
    });
});