import { ifNo, getRandomInteger } from './shared.js';
// settings
const headerData = {
    name: 'λndy Raddatz',
    title: 'Professional Technologist',
    animateName: true
}
const defaultAnimationName = 'fadeInDown';

function getRandomFadeIn() {
    const allAnimations = [
        'fadeInDown',
        'fadeInLeft',
        'fadeInRight',
        'fadeInUp'
    ];
    return allAnimations[getRandomInteger(0, allAnimations.length)];
}

function getRandomBackIn() {
    const allAnimations = [
        'backInDown',
        'backInLeft',
        'backInRight',
        'backInUp'
    ];
    return allAnimations[getRandomInteger(0, allAnimations.length)];
}
export function buildHeader() {

    console.log('building header');

    document.getElementById('header-subtitle').innerHTML = `
        <small class="d-inline-block text-body-secondary">
            ${animateLetters(headerData.title, 1, 3, 0, getRandomFadeIn(), true, getRandomFadeIn)}
        </small>`

    document.getElementById('header-subtext').innerHTML = `
    <p class="mx-auto h-100 w-100 overflow-x-hidden overflow-y-hidden">
        <span class="text-nowrap">
            ${animateLetters("putting", 1, 3, 0, getRandomBackIn())}
            ${animateLetters("the bits", 1, 4, 0, getRandomBackIn())}
            ${animateLetters("together", 1, 4, 1, getRandomBackIn())}
        </span>
        <span class="text-nowrap">
            <small class="text-body-secondary">
            ${animateLetters('in the best ways', 2, 4, 2, getRandomBackIn(), true, getRandomBackIn)}
            </small>
        </span>
        </p>`
}
function animateLetters(text, minDuration, maxDuration, delay, animationName, randomizeLetters, fnRandomizeLetters) {
    // debugger
    randomizeLetters = ifNo(randomizeLetters, false);
    delay = ifNo(delay, 0);
    animationName = ifNo(animationName, defaultAnimationName);

    // first split the text on whitespace
    var segments = text.split(/\s/);

    // then map each segment to a span
    return segments.map(segment => {

        // combine in a text-nowrap so that words stay together
        return '<span class="text-nowrap">' + [...segment].map(letter => {

            if (randomizeLetters) animationName = fnRandomizeLetters();

            var duration = getRandomInteger(minDuration, maxDuration);

            let returnHtml = `<span 
            class="d-inline-block animated ${animationName}" 
            style="animation-duration:${duration}s;`;

            if (delay) returnHtml += `animation-delay:${delay}s;`;

            returnHtml += `">${letter}</span>`

            return returnHtml;

        }).join('') + '</span>';
    }).join(' ')
}