const htmlElement = document.querySelector('html')
const theme = document.getElementById('theme-check')
const sunIcon = document.getElementById('theme-icon-sun')
const moonIcon = document.getElementById('theme-icon-moon')

function setLightMode() {
    theme.checked = true;
    localStorage.setItem('theme', 'light');
    htmlElement.setAttribute('data-bs-theme', 'light');
    sunIcon.classList.remove('d-none');
    moonIcon.classList.add('d-none');
}

function setDarkMode() {
    theme.checked = false;
    localStorage.setItem('theme', 'dark');
    htmlElement.setAttribute('data-bs-theme', 'dark');
    sunIcon.classList.add('d-none');
    moonIcon.classList.remove('d-none');
}

theme.addEventListener('change', (event) => {
    if (!event.currentTarget.checked) setDarkMode();
    else setLightMode();
})

export function loadTheme() {
    // detect last saved theme or browser default theme or default to light
    if (localStorage.getItem('theme') === 'light') {
        setLightMode();
    }
    else if (localStorage.getItem('theme') === 'dark' || window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setDarkMode();
    }
    else
        setLightMode();
};