import { ifNo } from './shared.js';
import { apexCharts, chartJS } from './charts.js';
import { Chart } from 'chart.js'
Chart.defaults.font.family = "'Fira Code', sans-serif";
import ApexCharts from 'apexcharts'

function getTextWithIcon(iconClasses, text) {
    return `<span class="text-nowrap"><i class="${iconClasses}"></i> ${text}</span>`;
}

const now = new Date();
// const defaultCardClasses = 'col-12 col-xl-6 mb-5 mb-xl-2';
const defaultCardClasses = 'col-12 mb-5 col-xl-8 offset-xl-2 mb-xl-3';
const cards = [
    {
        // title: 'Skills (years)',
        display: true,
        colClasses: defaultCardClasses + ' order-1',
        chartId: 'exp-chart',
        chartJSOptions: chartJS.barChartOptions,
        chartJSOptionsSmall: chartJS.barChartOptionsSmall,
        // chartJSOptions: chartJS.multiSeriesPieChartOptions,
        // apexChartOptions: apexCharts.sampleOptions2,        
    },
    {
        // title: 'Platforms',
        display: false,
        colClasses: defaultCardClasses + ' order-4',
        textCenter: true,
        separators: false,
        listGroupItemsHtml: [
            `
            ${getTextWithIcon('fa-solid fa-code', 'C#')}
            ${getTextWithIcon('fa-regular fa-file-lines', 'ASP.NET')}
            ${getTextWithIcon('fa-solid fa-server', 'Entity Framework')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-html5', 'HTML')}
            ${getTextWithIcon('fa-brands fa-css3-alt', 'CSS')}
            ${getTextWithIcon('fa-brands fa-js', 'JavaScript')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-microsoft', 'MSSQL')}
            ${getTextWithIcon('fa-regular fa-file-code', 'T-SQL')}
            ${getTextWithIcon('fa-solid fa-database', 'MySQL')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-windows', 'Windows')}
            ${getTextWithIcon('fa-solid fa-address-book', 'Active Directory')}
            ${getTextWithIcon('fa-solid fa-file-code', 'IIS')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-linux', 'Linux')}
            ${getTextWithIcon('fa-brands fa-ubuntu', 'Ubuntu')}
            ${getTextWithIcon('fa-brands fa-raspberry-pi', ' Raspberry Pi')}
            `,
            `
            ${getTextWithIcon('fa-solid fa-network-wired', 'Networking')}
            ${getTextWithIcon('fa-solid fa-computer', ' Hardware')}
            ${getTextWithIcon('fa-solid fa-lock', ' Security')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-docker', ' Docker')}
            ${getTextWithIcon('fa-solid fa-desktop', ' Virtualization')}
            `,
            `
            ${getTextWithIcon('fa-brands fa-microsoft', ' Azure')}
            ${getTextWithIcon('fa-brands fa-aws', ' AWS')}
            ${getTextWithIcon('fa-brands fa-cloudflare', ' Cloudflare')}
            `,
            `
            ${getTextWithIcon('fa-solid fa-ship', ' Leadership')}
            ${getTextWithIcon('fa-solid fa-people-group', 'Management')}
            `,
            `
            ${getTextWithIcon('fa-solid fa-user', 'UI/UX')}
            ${getTextWithIcon('fa-solid fa-person-through-window', 'Creativity')}
            `
        ]
    },
    {
        title: 'Proficiencies',
        display: false,
        colClasses: defaultCardClasses + ' order-3',
        listGroupItemsHtml: [
            'Full stack web development',
            'User experience design',
            'Business analysis and strategy',
            'Data modeling',
            'Operational infrastructure',
            'Process visualization',
            'Business reporting'
        ]
    },
    {
        title: 'Experience',
        colClasses: defaultCardClasses + ' order-2',
        display: false,
        listGroupItemsHtml: [
            now.getFullYear() - 2006 + '+ years as a professional technologist',
            now.getFullYear() - 2010 + '+ years as a SQL administrator and developer',
            now.getFullYear() - 2013 + '+ years of full-stack web development',
            'IP routing, switching, networking, and firewalls',
            'Senior leadership and management'
        ]
    }
    ,
    {
        title: 'Languages',
        display: false,
        order: 2,
        listGroupItemsHtml: [
            'C# / SQL',
            'HTML / CSS / JavaScript',
            'Bash / Powershell',
            'Will use any language fit for a purpose'
        ]
    }
    ,
    {
        title: 'Learning',
        order: 4,
        display: false,
        listGroupItemsHtml: [
            // 'Python',
            'Data science / machine learning / AI',
            'Crypto / Web3 / <a href="https://chia.net" class="link-secondary" target="_blank">Chia</a>',
        ]
    }
]
export function buildCards() {

    let displayedCards = cards
        .filter(c => ifNo(c.display, true));

    document.getElementById('cards-row').innerHTML = displayedCards
        .map(card => buildCard(card)).join(' ');

    displayedCards
        .filter(card => card.chartId)
        .forEach(card => buildChartJS(card.chartId, card.chartJSOptions, ifNo(card.chartJSOptionsSmall, card.chartJSOptions)));
    // .forEach(card => buildApexChart(card.chartId, card.apexChartOptions));
}

function buildApexChart(chartId, apexChartOptions) {

    if (!chartId) return;

    const ctx = document.getElementById(chartId);

    // apexcharts
    new ApexCharts(ctx, apexChartOptions).render();
}
function buildChartJS(chartId, chartJSOptions, chartJSOptionsSmall) {

    if (!chartId) return;

    const ctx = document.getElementById(chartId);
    const ctxSmall = document.getElementById(chartId + '-sm');

    // chart.js
    new Chart(ctx, chartJSOptions);
    new Chart(ctxSmall, chartJSOptionsSmall);
}

function buildCard(card) {
    var returnHtml = `
        <div class="${ifNo(card.colClasses, defaultCardClasses)}">
        <section class="h-100">
            <div class="card border-0 h-100">`;

    if (card.title) {
        returnHtml += `
                <div class="card-header ${card.textCenter ? 'text-center' : ''}">${card.title}</div>`;
    }
    if (card.listGroupItemsHtml && card.listGroupItemsHtml.length) {

        // list group
        var listCss = card.listInline ? 'list-inline text-center' : 'list-group list-group-flush';
        var listItemCss = card.listInline ? 'list-inline-item m-3' : 'list-group-item';
        if (card.textCenter) { listCss += ' text-center my-auto'; }
        if (card.separators === false) { listItemCss += ' border-0'; }
        returnHtml +=
            `<ul class="${listCss}">${card.listGroupItemsHtml.map(item =>
                `<li class="${listItemCss}">${item}</li>`).join(' ')}
            </ul>`
    }

    // chart
    if (card.chartId) {
        if (card.apexChartOptions) {
            returnHtml += `
                <div id="${card.chartId}"></div>
            `;
        }
        else if (card.chartJSOptions) {

            returnHtml += `
            <div class="d-none d-sm-flex h-100 w-100">
                <canvas class="my-auto" id="${card.chartId}"></canvas>
            </div>
            <div class="d-block d-sm-none" style="position: relative; height:100%; width:100%; min-height:425px;">
            <canvas id="${card.chartId}-sm"></canvas>
            </div>
            `;
        }
    }
    returnHtml += `</div>
        </div>
</section>
        `
    return returnHtml;
}